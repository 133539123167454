import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FlightSearch from './FlightSearch';
import Sidebar from './Sidebar';
import Header from './Header';
import CategorySection from './CategorySection';
import FareSection from './FareSection';
import TripType from './TripType';
import backgroundImage from '../icon/background.jpg';

function Navbar(props) {

  return (
    <div>
      <div className="d-flex full_dashboard" style={{ backgroundImage: `url(${backgroundImage})`  }}>
        <Sidebar />
        <div className='container side-container'>
        <Header/>
          <CategorySection />
          <div className='container dashboard'>
            <div className='d-flex pt-3'>
              <TripType />
            </div>
            <FlightSearch />
            <div>
              <FareSection />
            </div>
            <div className='text-center search_button_div'>
              <a className='btn btn-primary search-button' href=''>SEARCH</a>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

Navbar.propTypes = {}

export default Navbar;
