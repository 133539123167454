import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navbar';
import LogIn from './components/login/LogIn';
// import Calendar from './components/Calendar';


import './App.css';

function App() {
 
  return (
    <>
    {/* <Navbar/> */}
    <LogIn/>
    {/* <Calendar/> */}
    </>
    
  );
}

export default App;
