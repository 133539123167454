import React, { useState, useEffect } from "react";
import downArrow from '../../icon/down-arrow.png';
import location from '../../icon/location.png';
import slider1 from '../../icon/login/slider1.jpg';
import slider2 from '../../icon/login/slider2.jpg';
import slider3 from '../../icon/login/slider3.jpg';
import companyLogo from '../../icon/login/company-name.jpg';

export default function LogIn() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const images = [slider1, slider2, slider3];
  const handleLogin = (e) => {
    e.preventDefault();
    console.log("Logging in with", username, password);
  };


  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 500);

    return () => clearInterval(intervalId);
  }, [currentIndex, images.length]);

  return (
    <>
      <div className="outer_login">
        <div className="header_section">
          <header className="login_header">
            <nav>
              <ul>
                <li className="header_logo_section"><img src={companyLogo} className="header_logo"/></li>
                <li><a href="/">Partner With Us</a></li>
                <li><a href="/about">Register now</a></li>
              </ul>
            </nav>
          </header>
        </div>
        <div className="login_dashboard">
          <div className="login_page_dashboard_section">
            <div>
              <div style={{fontSize:'30px'}}>Welcom to Tripzen connect</div>
              <p>A B2B Travel Portal Built Exclusively For Our Travel Partner</p>
            </div>
            <div>
              <div className="slider" style={{ margin: 0, padding: 0 }}>
                {images.map((image, index) => (
                  <div key={index} className={index === currentIndex ? "slide active slider_image" : "slide slider_image"}>
                    {index === currentIndex && (
                      <div className="image-wrapper">
                        <img src={image} alt={`Slide ${index}`} />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="login_page_section">
            <h4>SIGN IN</h4>
            <div className="login_inner_section">
              <form onSubmit={handleLogin}>
                <div>
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="TERMINAL ID (14 DIGITS)"
                    className="input-field"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                    className="input-field"
                  />
                </div>
                <div>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    className="input-field"
                  />
                </div>
                <button type="submit" className="login-button">Login</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
